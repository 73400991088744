.inspectionScreen {
    background-color: aliceblue;
    height: auto;
    padding: 10px;
    .MuiBreadcrumbs-ol {
        padding: 0px;
        margin: 0px !important;
    }
}
.table_component {
    width: 100% !important;
    padding-top: 0px;
}

.check-btn {
    .MuiButton-root {
        min-width: 30px !important;
        padding: 4px 0px;
    }
}
