.Loan_Veh_heading {
    text-align: left;
    font-size: 16px !important;
    font-weight: 500;
    padding: 5px 0px;
    // text-decoration: underline;
    color: #f50057;

    // background-color: red;
}
.mainSection {
    // background-color: #f5f5f5;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
