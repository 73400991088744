// // body {
// //     font-size: 16px;
// //     font-family: Montserrat !important;
// //     background-color: #e9eaea;
// // }

// .portal-info-height {
//     height: 5vw !important;
// }
// .appBar-height {
//     top: 5vw !important;
// }

// // new css Code ..............
// .CircularProgressbar .CircularProgressbar-text {
//     dominant-baseline: central !important;
// }

// .bo-Graph {
//     width: 100%;
//     // height: 150px !important;
//     height: 140px !important;
// }

// .ringProperty {
//     height: 8.333vw !important;
//     width: 8.333vw !important;
//     padding: 12px 10px;
//     font-weight: 700;
// }

// .PanLringProperty {
//     height: 7.333vw !important;
//     width: 7.333vw !important;
//     padding: 0px 10px 12px 10px;
//     font-weight: 700;
// }

// .bo-GraphHeadingPadding {
//     padding: 20px 10px 12px 6px !important;
// }

// .AvtarIcon {
//     width: 1.625rem;
//     height: 1.625rem;
//     line-height: 1;
//     display: flex;
//     padding: 4px;
//     align-items: center;
//     user-select: none;
//     border-radius: 50%;
//     justify-content: center;
//     .AvatarFont {
//         font-size: 1.125rem;
//         color: white;
//     }
// }

// .budgetLebels {
//     font-size: 10px !important;
// }

// .mainHeading {
//     text-transform: uppercase;
//     font-weight: bold;
//     font-size: 28px;
//     width: 100%;
// }

// .secondaryHeading {
//     text-transform: uppercase;
//     font-weight: 600;
//     font-size: 21px;
// }

// .type1_Text {
//     // text-transform: uppercase;
//     font-weight: bold;
//     font-size: 21px !important;
// }
// .type2_Text {
//     font-weight: 600;
//     font-size: 21px !important;
// }

// .type3_Text {
//     text-transform: uppercase;
//     font-weight: 500;
//     font-size: 14px !important;
// }

// .profitloss_Card {
//     background-color: white;
//     height: 11.458vw;
//     border-radius: 1.25rem;
//     font-size: 40px;
// }

// .branchOverViewKip_card {
//     background-color: white;
//     height: 190px;
//     border-radius: 1.25rem;
//     padding: 0px 10px;
// }

// .branchOverGraph_card {
//     background-color: white;
//     height: 11.458vw !important;
//     border-radius: 1.25rem;
//     padding: 0px 10px;
// }

// .containerPadding {
//     padding: 0.75em 0.375em;
// }

// .alignLeft {
//     text-align: left !important;
// }
// .mt36 {
//     margin: 36px 0px 36px 0px;
// }

// .screenMainHeader {
//     margin: 0px 0px 0px 8px;
//     // margin: 2% 0% 1% 0.5%;
// }

// .p4 {
//     padding: 4px;
// }

// .w50 {
//     width: 50%;
// }

// .w100 {
//     width: 100%;
// }

// .salesBudgetScreen {
//     background-color: #e9eaea;

//     padding: 0px 10px;
//     .MuiBreadcrumbs-ol {
//         padding: 10px 0px 0px 0px !important;
//         margin: 0px !important;
//     }
// }

// .budgetLebels {
//     font-size: 0.75em !important;
// }

// .kpiCard {
//     padding: 0.8rem;

//     border-radius: 1.25rem;
//     box-shadow: rgba(17, 17, 26, 0.1) 0rem 0.25rem 1rem, rgba(17, 17, 26, 0.05) 0rem 0.5rem 2rem;
//     background-color: white;
// }

// .weekField {
//     background-color: white;
//     width: 95%;
//     padding: 10px;
//     height: 26px;
//     border: 1px solid rgba(0, 0, 0, 0.3);
//     border-radius: 3px;
//     font-size: 15px;
// }

// .week {
//     .MuiOutlinedInput-input {
//         padding: 15.5px 14px !important;
//         background-color: white !important;
//     }
// }

// // Used Stock Valuation //
// .USVbranchOverViewKip_card {
//     background-color: white;
//     height: 192px;
//     border-radius: 1.25rem;
//     padding: 10px 15px;
//     text-align: left;
// }

// .USVUnitKipOne_card {
//     background-color: white;
//     height: 165px;
//     border-radius: 1.25rem;
// }

// .USVUnitKipTwo_card {
//     background-color: white;
//     height: 165px;
//     border-radius: 1.25rem;
//     padding: 20px 15px;
//     text-align: left;
// }

// .USVUnitKipThree_card {
//     background-color: white;
//     height: 165px;
//     border-radius: 1.25rem;
//     padding: 20px 15px;
//     text-align: left;
// }

// .USVsecondaryHeading {
//     text-transform: uppercase;
//     font-weight: 600;
//     font-size: 21px;
// }

// .USVtype1_Text {
//     // text-transform: uppercase;
//     font-weight: bold;
//     font-size: 21px !important;
// }
// .USVtype2_Text {
//     font-weight: 600;
//     font-size: 21px !important;
// }

// .USVtype3_Text {
//     text-transform: uppercase;
//     font-weight: 500;
//     font-size: 14px !important;
// }
// // END //

// // Used Vehicle Stock //

// .USVGropupStockViewKip_card {
//     background-color: white;
//     height: 192px;
//     border-radius: 1.25rem;
//     padding: 10px 15px;
//     text-align: left;
// }

// .UVSsecondaryHeading {
//     text-transform: uppercase;
//     font-weight: 600;
//     font-size: 21px;
// }

// .UVStype1_Text {
//     // text-transform: uppercase;
//     font-weight: bold;
//     font-size: 21px !important;
// }
// .UVStype2_Text {
//     font-weight: 600;
//     font-size: 21px !important;
// }

// .UVStype3_Text {
//     text-transform: uppercase;
//     font-weight: 500;
//     font-size: 14px !important;
// }

// // END //

// // Aged Vehicle Stock  //

// .AVSUnitKipOne_card {
//     background-color: white;
//     height: 165px;
//     border-radius: 1.25rem;
// }

// .AVSUnitKipTwo_card {
//     background-color: white;
//     height: 165px;
//     border-radius: 1.25rem;
//     padding: 20px 15px;
//     text-align: left;
// }

// .AVSUnitKipThree_card {
//     background-color: white;
//     height: 165px;
//     border-radius: 1.25rem;
//     padding: 20px 15px;
//     text-align: left;
// }

// .AVSsecondaryHeading {
//     text-transform: uppercase;
//     font-weight: 600;
//     font-size: 21px;
// }

// .AVStype1_Text {
//     // text-transform: uppercase;
//     font-weight: bold;
//     font-size: 21px !important;
// }
// .AVStype2_Text {
//     font-weight: 600;
//     font-size: 21px !important;
// }

// .AVStype3_Text {
//     text-transform: uppercase;
//     font-weight: 500;
//     font-size: 14px !important;
// }
// // END //

// // Overage Priority List  //

// .OPLGropupStockViewKip_card {
//     background-color: white;
//     height: 192px;
//     border-radius: 1.25rem;
//     padding: 20px 15px;
//     text-align: left;
// }

// .OPLsecondaryHeading {
//     text-transform: uppercase;
//     font-weight: 600;
//     font-size: 21px;
// }

// .OPLtype1_Text {
//     // text-transform: uppercase;
//     font-weight: bold;
//     font-size: 21px !important;
// }
// .OPLtype2_Text {
//     font-weight: 600;
//     font-size: 21px !important;
// }

// .OPLtype3_Text {
//     text-transform: uppercase;
//     font-weight: 500;
//     font-size: 14px !important;
// }
// // END //

// // @media screen and (min-width: 1920px) {
// //     .CircularProgressbar .CircularProgressbar-text {
// //         dominant-baseline: central !important;
// //     }

// //     .bo-Graph {
// //         width: 100%;
// //         // height: 150px !important;
// //         height: 7.813vw !important;
// //     }

// //     .ringProperty {
// //         height: 8.333vw !important;
// //         width: 8.333vw !important;
// //         padding: 12px 10px;
// //         font-weight: 700;
// //     }

// //     .PanLringProperty {
// //         height: 7.333vw !important;
// //         width: 7.333vw !important;
// //         padding: 0px 10px 12px 10px;
// //         font-weight: 700;
// //     }

// //     .bo-GraphHeadingPadding {
// //         padding: 20px 10px 12px 6px !important;
// //     }

// //     .AvtarIcon {
// //         width: 1.625rem;
// //         height: 1.625rem;
// //         line-height: 1;
// //         display: flex;
// //         align-items: center;
// //         user-select: none;
// //         border-radius: 50%;
// //         justify-content: center;
// //         .AvatarFont {
// //             font-size: 1.125rem;
// //             color: white;
// //         }
// //     }

// //     .budgetLebels {
// //         font-size: 10px !important;
// //     }

// //     .mainHeading {
// //         text-transform: uppercase;
// //         font-weight: bold;
// //         font-size: 1.458vw;
// //         width: 100%;
// //     }

// //     .secondaryHeading {
// //         text-transform: uppercase;
// //         font-weight: 600;
// //         font-size: 1.094vw;
// //     }

// //     .type1_Text {
// //         // text-transform: uppercase;
// //         font-weight: bold;
// //         font-size: 1.094vw !important;
// //     }
// //     .type2_Text {
// //         font-weight: 600;
// //         font-size: 1.094vw !important;
// //     }

// //     .type3_Text {
// //         text-transform: uppercase;
// //         font-weight: 500;
// //         font-size: 0.729vw !important;
// //     }

// //     .profitloss_Card {
// //         background-color: white;
// //         height: 11.458vw;
// //         border-radius: 1.25rem;
// //         font-size: 40px;
// //     }

// //     .branchOverViewKip_card {
// //         background-color: white;
// //         height: 10vw;
// //         border-radius: 1.25rem;
// //         padding: 0px 10px;
// //     }

// //     .branchOverGraph_card {
// //         background-color: white;
// //         height: 11.458vw !important;
// //         border-radius: 1.25rem;
// //         padding: 0px 10px;
// //     }

// //     .budgetScreen {
// //         padding: 0px 10px;
// //         // background-color: rgba(0, 0, 0, 0.12);
// //     }

// //     .budgetLebels {
// //         font-size: 0.75em !important;
// //     }
// // }
// // @media screen and (max-width: 1680px) {
// //     .CircularProgressbar .CircularProgressbar-text {
// //         dominant-baseline: central !important;
// //     }

// //     .bo-Graph {
// //         width: 100%;
// //         // height: 150px !important;
// //         height: 7.813vw !important;
// //     }

// //     .ringProperty {
// //         height: 8.333vw !important;
// //         width: 8.333vw !important;
// //         padding: 12px 10px;
// //         font-weight: 700;
// //     }

// //     .PanLringProperty {
// //         height: 7.333vw !important;
// //         width: 7.333vw !important;
// //         padding: 7px 10px 15px 10px;
// //         font-weight: 700;
// //     }

// //     .bo-GraphHeadingPadding {
// //         padding: 18px 10px 18px 6px !important;
// //     }

// //     .AvtarIcon {
// //         width: 1.625rem;
// //         height: 1.625rem;
// //         line-height: 1;
// //         display: flex;
// //         align-items: center;
// //         user-select: none;
// //         border-radius: 50%;
// //         justify-content: center;
// //         .AvatarFont {
// //             font-size: 1.125rem;
// //             color: white;
// //         }
// //     }

// //     .budgetLebels {
// //         font-size: 10px !important;
// //     }

// //     .mainHeading {
// //         text-transform: uppercase;
// //         font-weight: bold;
// //         font-size: 1.458vw;
// //         width: 100%;
// //     }

// //     .secondaryHeading {
// //         text-transform: uppercase;
// //         font-weight: 600;
// //         font-size: 1.094vw;
// //     }

// //     .type1_Text {
// //         // text-transform: uppercase;
// //         font-weight: bold;
// //         font-size: 1.094vw !important;
// //     }
// //     .type2_Text {
// //         font-weight: 600;
// //         font-size: 1.094vw !important;
// //     }

// //     .type3_Text {
// //         text-transform: uppercase;
// //         font-weight: 500;
// //         font-size: 0.729vw !important;
// //     }

// //     .profitloss_Card {
// //         background-color: white;
// //         height: 11.458vw;
// //         border-radius: 1.25rem;
// //         font-size: 40px;
// //     }

// //     .branchOverViewKip_card {
// //         background-color: white;
// //         height: 10vw;
// //         border-radius: 1.25rem;
// //         padding: 0px 10px;
// //     }

// //     .branchOverGraph_card {
// //         background-color: white;
// //         height: 11.458vw !important;
// //         border-radius: 1.25rem;
// //         padding: 0px 10px;
// //     }

// //     .budgetScreen {
// //         padding: 0px 10px;
// //         // background-color: rgba(0, 0, 0, 0.12);
// //     }

// //     .budgetLebels {
// //         font-size: 0.75em !important;
// //     }
// // }
// // @media screen and (max-width: 1440px) {
// //     .CircularProgressbar .CircularProgressbar-text {
// //         dominant-baseline: central !important;
// //     }

// //     .bo-Graph {
// //         width: 100%;
// //         // height: 150px !important;
// //         height: 7.813vw !important;
// //     }

// //     .ringProperty {
// //         height: 8.333vw !important;
// //         width: 8.333vw !important;
// //         padding: 12px 10px;
// //         font-weight: 700;
// //     }

// //     .PanLringProperty {
// //         height: 7.333vw !important;
// //         width: 7.333vw !important;
// //         padding: 0px 10px 12px 10px;
// //         font-weight: 700;
// //     }

// //     .bo-GraphHeadingPadding {
// //         padding: 20px 10px 12px 6px !important;
// //     }

// //     .AvtarIcon {
// //         width: 1.625rem;
// //         height: 1.625rem;
// //         padding: 2px;
// //         .AvatarFont {
// //             font-size: 1.125rem;
// //             color: white;
// //         }
// //     }

// //     .budgetLebels {
// //         font-size: 10px !important;
// //     }

// //     .mainHeading {
// //         text-transform: uppercase;
// //         font-weight: bold;
// //         font-size: 1.458vw;
// //         width: 100%;
// //     }

// //     .secondaryHeading {
// //         text-transform: uppercase;
// //         font-weight: 600;
// //         font-size: 1.094vw;
// //     }

// //     .type1_Text {
// //         // text-transform: uppercase;
// //         font-weight: bold;
// //         font-size: 1.094vw !important;
// //     }
// //     .type2_Text {
// //         font-weight: 600;
// //         font-size: 1.094vw !important;
// //     }

// //     .type3_Text {
// //         text-transform: uppercase;
// //         font-weight: 500;
// //         font-size: 0.729vw !important;
// //     }

// //     .profitloss_Card {
// //         background-color: white;
// //         height: 11.458vw;
// //         border-radius: 1.25rem;
// //         font-size: 40px;
// //     }

// //     .branchOverViewKip_card {
// //         background-color: white;
// //         height: 10vw;
// //         border-radius: 1.25rem;
// //         padding: 0px 10px;
// //     }

// //     .branchOverGraph_card {
// //         background-color: white;
// //         height: 11.458vw !important;
// //         border-radius: 1.25rem;
// //         padding: 0px 10px;
// //     }

// //     .budgetScreen {
// //         padding: 0px 10px;
// //         // background-color: rgba(0, 0, 0, 0.12);
// //     }

// //     .budgetLebels {
// //         font-size: 0.75em !important;
// //     }
// // }
// // @media screen and (max-width: 1366px) {
// //     .ringProperty {
// //         height: 8.333vw !important;
// //         width: 8.333vw !important;
// //         padding: 12px 10px;
// //         font-weight: 700;
// //     }

// //     .PanLringProperty {
// //         height: 7.333vw !important;
// //         width: 7.333vw !important;
// //         padding: 0px 10px 12px 10px;
// //         font-weight: 700;
// //     }

// //     .bo-GraphHeadingPadding {
// //         padding: 20px 10px 12px 6px !important;
// //     }

// //     .AvtarIcon {
// //         width: 1.2rem;
// //         height: 1.2rem;
// //         padding: 2px;
// //         .AvatarFont {
// //             font-size: 0.8rem;
// //             color: white;
// //         }
// //     }

// //     .budgetLebels {
// //         font-size: 10px !important;
// //     }

// //     .mainHeading {
// //         text-transform: uppercase;
// //         font-weight: bold;
// //         font-size: 1.458vw;
// //         width: 100%;
// //     }

// //     .secondaryHeading {
// //         text-transform: uppercase;
// //         font-weight: 600;
// //         font-size: 1.094vw;
// //     }

// //     .type1_Text {
// //         // text-transform: uppercase;
// //         font-weight: bold;
// //         font-size: 1.094vw !important;
// //     }
// //     .type2_Text {
// //         font-weight: 600;
// //         font-size: 1.094vw !important;
// //     }

// //     .type3_Text {
// //         text-transform: uppercase;
// //         font-weight: 500;
// //         font-size: 0.729vw !important;
// //     }

// //     .profitloss_Card {
// //         background-color: white;
// //         height: 11.458vw;
// //         border-radius: 1.25rem;
// //         font-size: 40px;
// //     }

// //     .branchOverViewKip_card {
// //         background-color: white;
// //         height: 10vw;
// //         border-radius: 1.25rem;
// //         padding: 0px 10px;
// //     }

// //     .branchOverGraph_card {
// //         background-color: white;
// //         height: 11.458vw !important;
// //         border-radius: 1.25rem;
// //         padding: 0px 10px;
// //     }

// //     .budgetScreen {
// //         padding: 0px 10px;
// //         // background-color: rgba(0, 0, 0, 0.12);
// //     }

// //     .budgetLebels {
// //         font-size: 0.75em !important;
// //     }
// // }
// // @media screen and (min-width: 1025px) and (max-width: 1280px) {
// //     .screenMainHeader {
// //         margin: 25px 0px 14px 6px;
// //     }
// //     .portal-info-height {
// //         height: 85px !important;
// //     }
// //     .appBar-height {
// //         top: 85px !important;
// //     }
// //     .mainHeading {
// //         text-transform: uppercase;
// //         font-weight: bold;
// //         font-size: 19px !important;
// //     }

// //     .secondaryHeading {
// //         text-transform: uppercase;
// //         font-weight: 600;
// //         font-size: 14px !important;
// //     }

// //     .type1_Text {
// //         // text-transform: uppercase;
// //         font-weight: bold;
// //         font-size: 14px !important;
// //     }
// //     .type2_Text {
// //         font-weight: 600;
// //         font-size: 14px !important;
// //     }

// //     .type3_Text {
// //         text-transform: uppercase;
// //         font-weight: 500;
// //         font-size: 10px !important;
// //     }

// //     .profitloss_Card {
// //         background-color: white;
// //         height: 147px !important;
// //         border-radius: 1.25rem;
// //     }

// //     .branchOverViewKip_card {
// //         background-color: white;
// //         height: 129px !important;
// //         border-radius: 1.25rem;
// //         padding: 0px 10px;
// //     }

// //     .branchOverGraph_card {
// //         background-color: white;
// //         height: 139px !important;
// //         border-radius: 1.25rem;
// //         padding: 0px 10px;
// //     }

// //     .ringProperty {
// //         height: 106px !important;
// //         width: 106px !important;
// //         padding: 6px 4px;
// //         font-weight: 700;
// //     }

// //     .PanLringProperty {
// //         height: 94px !important;
// //         width: 94px !important;
// //         padding: 12px 10px;
// //         font-weight: 700;
// //     }

// //     .bo-Graph {
// //         width: 100%;
// //         // height: 150px !important;
// //         height: 107px !important;
// //     }
// //     .bo-GraphHeadingPadding {
// //         padding: 10px 10px 8px 6px !important;
// //     }
// //     .AvtarIcon {
// //         width: 1.325rem;
// //         height: 1.325rem;

// //         padding: 3px;

// //         .AvatarFont {
// //             font-size: 1.125rem;
// //             color: white;
// //         }
// //     }
// // }
// // @media screen and (min-width: 901px) and (max-width: 1024px) {
// //     .portal-info-height {
// //         height: 85px !important;
// //     }
// //     .appBar-height {
// //         top: 85px !important;
// //     }
// //     .ringProperty {
// //         height: 9.667vw !important;
// //         width: 9.667vw !important;
// //         padding: 6px 5px;
// //         font-weight: 700;
// //     }

// //     .PanLringProperty {
// //         height: 9.644vw !important;
// //         width: 9.644vw !important;
// //         padding: 5px 0px 6px 5px;
// //         font-weight: 700;
// //     }

// //     .bo-GraphHeadingPadding {
// //         padding: 20px 10px 12px 6px !important;
// //     }

// //     .AvtarIcon {
// //         width: 1.625rem;
// //         height: 1.625rem;
// //         line-height: 1;
// //         display: flex;
// //         align-items: center;
// //         user-select: none;
// //         border-radius: 50%;
// //         justify-content: center;
// //         .AvatarFont {
// //             font-size: 1.125rem;
// //             color: white;
// //         }
// //     }

// //     .budgetLebels {
// //         font-size: 10px !important;
// //     }

// //     .mainHeading {
// //         text-transform: uppercase;
// //         font-weight: bold;
// //         font-size: 2.734vw !important;
// //         width: 100%;
// //     }

// //     .secondaryHeading {
// //         text-transform: uppercase;
// //         font-weight: 600;
// //         font-size: 1.551vw !important;
// //     }

// //     .type1_Text {
// //         // text-transform: uppercase;
// //         font-weight: bold;
// //         font-size: 1.551vw !important;
// //     }
// //     .type2_Text {
// //         font-weight: 600;
// //         font-size: 1.551vw !important;
// //     }

// //     .type3_Text {
// //         text-transform: uppercase;
// //         font-weight: 500;
// //         font-size: 1.167vw !important;
// //     }

// //     .profitloss_Card {
// //         background-color: white;
// //         height: auto !important;
// //         padding: 10px !important;
// //         border-radius: 1.25rem;
// //         font-size: 40px;
// //     }

// //     .branchOverViewKip_card {
// //         background-color: white;
// //         height: 16.833vw !important;
// //         padding: 0px 10px;
// //         border-radius: 1.25rem;
// //     }

// //     .branchOverGraph_card {
// //         background-color: white;
// //         height: 139px !important;
// //         border-radius: 1.25rem;
// //         padding: 0px 10px;
// //     }
// //     .bo-Graph {
// //         width: 100%;
// //         // height: 150px !important;
// //         height: 107px !important;
// //     }
// //     .bo-GraphHeadingPadding {
// //         padding: 10px 10px 8px 6px !important;
// //     }

// //     .budgetScreen {
// //         padding: 0px 10px;
// //         // background-color: rgba(0, 0, 0, 0.12);
// //     }

// //     .budgetLebels {
// //         font-size: 0.75em !important;
// //     }
// // }
// // @media screen and (min-width: 601px) and (max-width: 900px) {
// //     .screenMainHeader {
// //         margin: 12px 0px 14px 6px;
// //     }
// //     .portal-info-height {
// //         height: 85px !important;
// //     }
// //     .appBar-height {
// //         top: 85px !important;
// //     }
// //     .ringProperty {
// //         height: 11.667vw !important;
// //         width: 11.667vw !important;
// //         padding: 6px 5px;
// //         font-weight: 700;
// //     }

// //     .PanLringProperty {
// //         height: 15.644vw !important;
// //         width: 15.644vw !important;
// //         padding: 0px 10px 12px 10px;
// //         font-weight: 700;
// //     }

// //     .branchOverGraph_card {
// //         background-color: white;
// //         height: 139px !important;
// //         border-radius: 1.25rem;
// //         padding: 0px 10px;
// //     }
// //     .bo-Graph {
// //         width: 100%;
// //         // height: 150px !important;
// //         height: 107px !important;
// //     }
// //     .bo-GraphHeadingPadding {
// //         padding: 10px 10px 8px 6px !important;
// //     }

// //     .AvtarIcon {
// //         width: 1.625rem;
// //         height: 1.625rem;
// //         line-height: 1;
// //         display: flex;
// //         align-items: center;
// //         user-select: none;
// //         border-radius: 50%;
// //         justify-content: center;
// //         .AvatarFont {
// //             font-size: 1.125rem;
// //             color: white;
// //         }
// //     }

// //     .budgetLebels {
// //         font-size: 10px !important;
// //     }

// //     .mainHeading {
// //         text-transform: uppercase;
// //         font-weight: bold;
// //         font-size: 3.111vw;
// //         width: 100%;
// //     }

// //     .secondaryHeading {
// //         text-transform: uppercase;
// //         font-weight: 600;
// //         font-size: 2.333vw;
// //     }

// //     .type1_Text {
// //         // text-transform: uppercase;
// //         font-weight: bold;
// //         font-size: 2.333vw !important;
// //     }
// //     .type2_Text {
// //         font-weight: 600;
// //         font-size: 2.333vw !important;
// //     }

// //     .type3_Text {
// //         text-transform: uppercase;
// //         font-weight: 500;
// //         font-size: 1.556vw !important;
// //     }

// //     .profitloss_Card {
// //         background-color: white;
// //         height: 22.444vw !important;

// //         border-radius: 1.25rem;
// //         font-size: 40px;
// //     }

// //     .branchOverViewKip_card {
// //         background-color: white;
// //         height: 21.833vw !important;
// //         padding: 0px 10px;
// //         border-radius: 1.25rem;
// //     }

// //     // .branchOverGraph_card {
// //     //     background-color: white;
// //     //     height: 22.444vw !important;
// //     //     padding: 0px 10px;
// //     //     border-radius: 1.25rem;
// //     // }

// //     .budgetScreen {
// //         padding: 0px 10px;
// //         // background-color: rgba(0, 0, 0, 0.12);
// //     }

// //     .budgetLebels {
// //         font-size: 0.75em !important;
// //     }
// // }
// // @media screen and (max-width: 600px) {
// //     .screenMainHeader {
// //         margin: 12px 0px 14px 6px;
// //     }
// //     .portal-info-height {
// //         height: 85px !important;
// //     }
// //     .appBar-height {
// //         top: 85px !important;
// //     }
// //     // .bo-Graph {
// //     //     width: 100%;
// //     //     // height: 150px !important;
// //     //     height: 3.5vw !important;
// //     // }

// //     .ringProperty {
// //         height: 26.666vw !important;
// //         width: 26.666vw !important;
// //         padding: 12px 10px;
// //         font-weight: 700;
// //     }

// //     .PanLringProperty {
// //         height: 23.466vw !important;
// //         width: 23.466vw !important;
// //         padding: 0px 10px 12px 10px;
// //         font-weight: 700;
// //     }

// //     .bo-GraphHeadingPadding {
// //         padding: 20px 10px 12px 6px !important;
// //     }

// //     .AvtarIcon {
// //         width: 1.625rem;
// //         height: 1.625rem;
// //         line-height: 1;
// //         display: flex;
// //         align-items: center;
// //         user-select: none;
// //         border-radius: 50%;
// //         justify-content: center;
// //         .AvatarFont {
// //             font-size: 1.125rem;
// //             color: white;
// //         }
// //     }

// //     .budgetLebels {
// //         font-size: 10px !important;
// //     }

// //     .mainHeading {
// //         text-transform: uppercase;
// //         font-weight: bold;
// //         font-size: 4.667vw;
// //         width: 100%;
// //     }

// //     .secondaryHeading {
// //         text-transform: uppercase;
// //         font-weight: 600;
// //         font-size: 3.5vw;
// //     }

// //     .type1_Text {
// //         // text-transform: uppercase;
// //         font-weight: bold;
// //         font-size: 3.5vw !important;
// //     }
// //     .type2_Text {
// //         font-weight: 600;
// //         font-size: 3.5vw !important;
// //     }

// //     .type3_Text {
// //         text-transform: uppercase;
// //         font-weight: 500;
// //         font-size: 2.333vw !important;
// //     }

// //     .profitloss_Card {
// //         background-color: white;
// //         height: auto !important;
// //         padding: 20px !important;
// //         border-radius: 1.25rem;
// //         font-size: 40px;
// //     }

// //     .branchOverViewKip_card {
// //         background-color: white;
// //         height: 32vw !important;
// //         padding: 0px 10px !important;
// //         border-radius: 1.25rem;
// //     }
// //     .branchOverGraph_card {
// //         background-color: white;
// //         height: 139px !important;
// //         border-radius: 1.25rem;
// //         padding: 0px 10px;
// //     }
// //     .bo-Graph {
// //         width: 100%;
// //         // height: 150px !important;
// //         height: 107px !important;
// //     }
// //     .bo-GraphHeadingPadding {
// //         padding: 10px 10px 8px 6px !important;
// //     }

// //     .budgetScreen {
// //         padding: 0px 10px;
// //         // background-color: rgba(0, 0, 0, 0.12);
// //     }

// //     .budgetLebels {
// //         font-size: 0.75em !important;
// //     }
// // }

body {
    font-size: 16px;
    font-family: Montserrat !important;
    // background-color: #e9eaea !important;
}

.portal-info-height {
    height: 95px !important;
}
.appBar-height {
    top: 95px !important;
}

// new css Code ..............
.CircularProgressbar .CircularProgressbar-text {
    dominant-baseline: central !important;
}

.bo-Graph {
    width: 100%;
    height: 7.9vw !important;
}

.USV-Graph {
    width: 100%;
    height: 151.68px !important;
}

.ringProperty {
    height: 8.333vw !important;
    width: 8.333vw !important;
    padding: 12px 10px;
    font-weight: 700;
}

.PanLringProperty {
    height: 7.333vw !important;
    width: 7.333vw !important;
    padding: 0px 10px;
    font-weight: 700;
}

.PanLDetailringProperty {
    height: 7.533vw !important;
    width: 6.333vw !important;
    // padding: 0px 10px;
    font-weight: 700;
}

.bo-GraphHeadingPadding {
    padding: 20px 10px 12px 6px !important;
}

.AvtarIcon {
    width: 1.625rem;
    height: 1.625rem;
    line-height: 1;
    display: flex;
    padding: 4px;
    align-items: center;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    .AvatarFont {
        font-size: 1.125em;
        color: white;
    }
}

.budgetLebels {
    font-size: 10px !important;
}

.mainHeading {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 28px;
    width: 100%;
}

.secondaryHeading {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.094vw;
}

.type1_Text {
    // text-transform: uppercase;
    font-weight: bold;
    font-size: 1.094vw !important;
}
.type2_Text {
    font-weight: 600;
    font-size: 1.094vw !important;
}

.type3_Text {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px !important;
}

.profitloss_Card {
    background-color: white;
    height: 11.458vw;
    border-radius: 1.25rem;
    font-size: 40px;
}

.branchOverViewKip_card {
    background-color: white;
    height: 10vw;
    border-radius: 1.25rem;
    padding: 0px 10px;
}

// Branch Overview //

.BOprofitloss_Card {
    background-color: white;
    height: 220px;
    border-radius: 1.25rem;
    font-size: 40px;
}

.BObranchOverViewKip_card {
    background-color: white;
    height: 192px;
    border-radius: 1.25rem;
    padding: 0px 10px;
}

.BOsecondaryHeading {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 21px;
}

.BOringProperty {
    height: 160px !important;
    width: 160px !important;
    padding: 12px 10px;
    font-weight: 700;
}

.BOGraph_card {
    background-color: white;
    height: 220px !important;
    border-radius: 1.25rem;
    padding: 0px 10px;
}

.BOGraph {
    width: 100%;
    height: 150px !important;
    // height: 7.9vw !important;
}

.BOGraphHeadingPadding {
    padding: 20px 10px 12px 6px !important;
}

.BOtype1_Text {
    // text-transform: uppercase;
    font-weight: bold;
    font-size: 21px !important;
}
.BOtype2_Text {
    font-weight: 600;
    font-size: 21px !important;
}

.BOtype3_Text {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px !important;
}

// END //

// Sales Profit And Loss //

.SPnLprofitloss_Card {
    background-color: white;
    height: 220px;
    border-radius: 1.25rem;
    font-size: 40px;
}

.SPnLbranchOverViewKip_card {
    background-color: white;
    height: 192px;
    border-radius: 1.25rem;
    padding: 0px 10px;
}

.SPnLsecondaryHeading {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 21px;
}

.SPnLtype1_Text {
    // text-transform: uppercase;
    font-weight: bold;
    font-size: 21px !important;
}

.SPnLtype2_Text {
    font-weight: 600;
    font-size: 21px !important;
}

.SPnLtype3_Text {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px !important;
}

// END //

// AFTERSALES PROFIT AND LOSS  //

.ASPnLprofitloss_Card {
    background-color: white;
    height: 220px;
    border-radius: 1.25rem;
    font-size: 40px;
    padding: 0px 10px;
}

.ASPnLsecondaryHeading {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 21px;
}

.ASPnLtype1_Text {
    // text-transform: uppercase;
    font-weight: bold;
    font-size: 21px !important;
}

.ASPnLtype2_Text {
    font-weight: 600;
    font-size: 21px !important;
}

.ASPnLtype3_Text {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px !important;
}

// END //

// PROFIT AND LOSS DETAIL //

.PnLDbranchOverViewKip_card {
    background-color: white;
    height: 192px;
    border-radius: 1.25rem;
    padding: 0px 10px;
}

.PnLDsecondaryHeading {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 21px;
}

.PnLDtype1_Text {
    // text-transform: uppercase;
    font-weight: bold;
    font-size: 21px !important;
}

.PnLDtype2_Text {
    font-weight: 600;
    font-size: 21px !important;
}

.PnLDtype3_Text {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px !important;
}

// END //

// Used Stock Valuation //
.USVbranchOverViewKip_card {
    background-color: white;
    height: 192px;
    border-radius: 1.25rem;
    padding: 20px 15px;
    text-align: left;
}

.USVUnitKipOne_card {
    background-color: white;
    height: 165px;
    border-radius: 1.25rem;
}

.USVUnitKipTwo_card {
    background-color: white;
    height: 165px;
    border-radius: 1.25rem;
    padding: 20px 15px;
    text-align: left;
}

.USVUnitKipThree_card {
    background-color: white;
    height: 165px;
    border-radius: 1.25rem;
    padding: 20px 15px;
    text-align: left;
}

.USVsecondaryHeading {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 21px;
}

.USVUnitHeading {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 28px;
    width: 100%;
}
.AVSUnitHeading {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 28px;
    width: 100%;
}

.USVtype1_Text {
    // text-transform: uppercase;
    font-weight: bold;
    font-size: 21px !important;
}
.USVtype2_Text {
    font-weight: 600;
    font-size: 21px !important;
}

.USVtype3_Text {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px !important;
}
// END //

// Used Vehicle Stock //

.USVGropupStockViewKip_card {
    background-color: white;
    height: 192px;
    border-radius: 1.25rem;
    padding: 20px 15px;
    text-align: left;
}

.UVSbranchOverViewKip_card {
    background-color: white;
    height: 192px;
    border-radius: 1.25rem;
    padding: 20px 15px;
    text-align: left;
}

.UVSsecondaryHeading {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 21px;
}

.UVSUnitHeading {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 28px;
    width: 100%;
}

.UVStype1_Text {
    // text-transform: uppercase;
    font-weight: bold;
    font-size: 21px !important;
}
.UVStype2_Text {
    font-weight: 600;
    font-size: 21px !important;
}

.UVStype3_Text {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px !important;
}

// END //

// Aged Vehicle Stock  //

.AVSUnitKipOne_card {
    background-color: white;
    height: 165px;
    border-radius: 1.25rem;
}

.AVSUnitKipTwo_card {
    background-color: white;
    height: 165px;
    border-radius: 1.25rem;
    padding: 20px 15px;
    text-align: left;
}

.AVSUnitKipThree_card {
    background-color: white;
    height: 165px;
    border-radius: 1.25rem;
    padding: 20px 15px;
    text-align: left;
}

.AVSsecondaryHeading {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 21px;
}

.AVSGraph {
    width: 100%;
    height: 192px !important;
}

.AVStype1_Text {
    // text-transform: uppercase;
    font-weight: bold;
    font-size: 21px !important;
}
.AVStype2_Text {
    font-weight: 600;
    font-size: 21px !important;
}

.AVStype3_Text {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px !important;
}
// END //

// Overage Priority List  //

.OPLGropupStockViewKip_card {
    background-color: white;
    height: 192px;
    border-radius: 1.25rem;
    padding: 20px 15px;
    text-align: left;
}

.OPLsecondaryHeading {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 21px;
}

.OPLtype1_Text {
    // text-transform: uppercase;
    font-weight: bold;
    font-size: 21px !important;
}
.OPLtype2_Text {
    font-weight: 600;
    font-size: 21px !important;
}

.OPLtype3_Text {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px !important;
}
// END //

// SALES REPORTING  //

.SRbranchOverViewKip_card {
    background-color: white;
    height: 192px;
    border-radius: 1.25rem;
    padding: 0px 10px;
}

.SRsecondaryHeading {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 21px;
}

.SRtype1_Text {
    // text-transform: uppercase;
    font-weight: bold;
    font-size: 21px !important;
}
.SRtype2_Text {
    font-weight: 600;
    font-size: 21px !important;
}

.SRtype3_Text {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px !important;
}
// END //

.branchOverGraph_card {
    background-color: white;
    height: 220px !important;
    border-radius: 1.25rem;
    padding: 0px 10px;
}

.containerPadding {
    padding: 0.75em 0.375em;
}

.alignLeft {
    text-align: left !important;
}
.mt36 {
    margin: 36px 0px 36px 0px;
}

.screenMainHeader {
    margin: 31px 0px 19px 6px;
    // margin: 2% 0% 1% 0.5%;
}

.p4 {
    padding: 4px;
}

.w50 {
    width: 50%;
}

.w100 {
    width: 100%;
}

.budgetScreen {
    padding: 0px 10px;
    // background-color: rgba(0, 0, 0, 0.12);
}

.budgetLebels {
    font-size: 0.75em !important;
}

.kpiCard {
    padding: 0.8rem;

    border-radius: 1.25rem;
    box-shadow: rgba(17, 17, 26, 0.1) 0rem 0.25rem 1rem, rgba(17, 17, 26, 0.05) 0rem 0.5rem 2rem;
    background-color: white;
}

.weekField {
    background-color: white;
    width: 95%;
    padding: 10px;
    height: 26px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    font-size: 15px;
}

.week {
    .MuiOutlinedInput-input {
        padding: 15.5px 14px !important;
        background-color: white !important;
    }
}

@media (-webkit-device-pixel-ratio: 1.25) {
    .recharts-responsive-container {
        zoom: 1.25;
    }
}

@media screen and (min-width: 1920px) {
}
@media screen and (max-width: 1680px) {
    .BOGraph {
        height: 140px !important;
    }
    .USVtype3_Text {
        font-size: 12px !important;
    }

    .AVStype3_Text {
        font-size: 13px !important;
    }

    .PanLringProperty {
        height: 8vw !important;
        width: 8vw !important;
        padding: 0px 10px !important;
        font-weight: 700;
    }

    .ASPnLprofitloss_Card {
        background-color: white;
        height: 192px;
        border-radius: 1.25rem;
        font-size: 40px;
        padding: 0px 10px;
    }

    .SPnLprofitloss_Card {
        background-color: white;
        height: auto !important;
        border-radius: 1.25rem;
        font-size: 40px;
        padding: 10px 10px !important;
    }
}
@media screen and (max-width: 1440px) {
    //bo
    .BOringProperty {
        height: 9vw !important;
        width: 9vw !important;
        padding: 4px 4px;
        font-weight: 700;
    }

    .BOsecondaryHeading {
        font-size: 1.319vw;
    }

    .BOtype3_Text {
        font-size: 0.833vw !important;
    }

    .BOtype2_Text {
        font-weight: 600;
        font-size: 1.319vw !important;
    }

    .BOtype1_Text {
        font-weight: bold;
        font-size: 1.319vw !important;
    }

    .BObranchOverViewKip_card {
        height: 13vw !important;
    }

    .BOGraph_card {
        height: 13.911vw !important;
    }

    .BOprofitloss_Card {
        height: 180px;
    }

    // P AND L

    .PanLringProperty {
        height: 8.333vw !important;
        width: 8.333vw !important;
        padding: 0px 10px;
        font-weight: 700;
    }

    //PanL Detail

    .PnLDtype3_Text {
        font-size: 12px !important;
    }

    .PnLDtype1_Text {
        font-size: 19px !important;
    }

    .PnLDbranchOverViewKip_card {
        height: 135px;
    }

    //USED STOCK VALUATION

    .USVUnitHeading {
        font-size: 20px;
    }

    .AVSUnitHeading {
        font-size: 18px;
    }

    //USED VEHICLE STOCK

    // OVERAGE PRIORITY LIST
    .OPLtype1_Text {
        font-size: 19px !important;
    }

    .OPLtype3_Text {
        font-size: 12px !important;
    }

    .AVStype3_Text {
        font-size: 11px !important;
    }
}
@media screen and (max-width: 1366px) {
    .BOringProperty {
        height: 8vw !important;
        width: 8vw !important;
    }

    .BOGraph_card {
        height: 14.911vw !important;
    }

    .USVtype3_Text {
        font-size: 11px !important;
    }

    .AVStype3_Text {
        font-size: 9px !important;
    }
}

@media screen and (max-width: 1280px) {
    .BOGraph_card {
        height: 15.99vw !important;
    }

    .PnLtype3_Text {
        font-size: 11px !important;
    }

    .USVtype3_Text {
        font-size: 10px !important;
    }

    .USVUnitHeading {
        font-size: 18px;
    }
    .USVtype1_Text {
        font-weight: bold;
        font-size: 19px !important;
    }

    .AVStype1_Text {
        font-size: 17px !important;
    }

    .AVStype3_Text {
        font-size: 8px !important;
    }

    .ASPnLprofitloss_Card {
        height: 177px;
    }

    .AvtarIcon {
        width: 19px;
        height: 19px;
    }
}

@media screen and (max-width: 1024px) {
    .mainHeading {
        font-size: 21px !important;
    }

    .BOringProperty {
        height: 121px !important;
        width: 121px !important;
    }
    .BOsecondaryHeading {
        font-size: 17px;
    }

    .BOtype3_Text {
        font-size: 14px !important;
    }

    .BOtype2_Text {
        font-weight: 600;
        font-size: 17px !important;
    }

    .BOtype1_Text {
        font-weight: bold;
        font-size: 17px !important;
    }

    .BOGraph_card {
        height: auto !important;
    }

    .BObranchOverViewKip_card {
        height: auto !important;
    }

    .PanLringProperty {
        height: 100px !important;
        width: 100px !important;
        padding: 0px 10px;
        font-weight: 700;
    }

    .SPnLtype3_Text {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 11px !important;
    }

    .USVUnitKipThree_card {
        height: auto;
    }

    .USVbranchOverViewKip_card {
        height: auto;
    }

    .USVtype3_Text {
        font-size: 12px !important;
    }

    .USVtype1_Text {
        font-size: 21px !important;
    }

    .USVUnitHeading {
        font-size: 28px;
    }

    .AVStype3_Text {
        font-size: 12px !important;
    }

    .AVSUnitHeading {
        font-size: 28px;
    }

    .USVGropupStockViewKip_card {
        height: auto;
    }

    .AVSUnitKipThree_card {
        height: auto;
    }
    .ASPnLprofitloss_Card {
        height: auto;
    }
    .AvtarIcon {
        width: 30px;
        height: 30px;
    }
    .PanLDetailringProperty {
        height: 145px !important;
        width: 100px !important;
        font-weight: 700;
    }
}
// @media screen and (min-width: 601px) and (max-width: 900px) {

// }
@media screen and (max-width: 600px) {
    .USVUnitKipTwo_card {
        height: auto;
        padding: 10px 15px;
    }

    .USVsecondaryHeading {
        font-size: 16px;
    }
    .UVSbranchOverViewKip_card {
        height: auto;
    }

    .AVSUnitKipTwo_card {
        height: auto;
        padding: 15px 9px;
    }

    .AVStype3_Text {
        font-size: 10px !important;

        // padding: 0px !important;
    }
    .ASPnLprofitloss_Card {
        height: auto;
    }

    .OPLGropupStockViewKip_card {
        height: auto;
    }

    .type3_Text {
        font-size: 12px !important;
    }
    .OPLtype3_Text {
        font-size: 9px !important;
    }

    .BOtype3_Text {
        font-size: 12px !important;
    }

    .USVtype1_Text {
        font-weight: 500;
        font-size: 19px !important;
    }
    .containerPadding {
        padding: 5px;
    }

    .mainHeading {
        font-size: 17px !important;
    }
    .screenMainHeader {
        margin: 12px 0px 10px 2px;
    }

    .USVtype2_Text {
        font-weight: 600;
        font-size: 15px !important;
    }

    .AVStype2_Text {
        font-weight: 600;
        font-size: 16px !important;
    }
    .AVStype1_Text {
        font-weight: 500;
        font-size: 19px !important;
    }
    .SRbranchOverViewKip_card {
        height: 120px;
        padding: 0px 0px;
    }
    .SRtype3_Text {
        font-size: 11px !important;
    }

    .SPnLprofitloss_Card {
        padding: 10px 10px 10px 0px !important;
    }

    .SPnLsecondaryHeading {
        font-size: 15px;
        margin-left: 10px;
    }

    .BOringProperty {
        height: 89px !important;
        width: 89px !important;
    }

    .BOprofitloss_Card {
        height: 120px;
    }
    .BOtype2_Text {
        font-weight: 500;
        font-size: 16px !important;
    }

    .BOtype3_Text {
        font-size: 10px !important;
    }

    .PnLDsecondaryHeading {
        font-size: 15px;
    }

    .PnLDtype1_Text {
        font-weight: 500;
        font-size: 18px !important;
    }
    .AVStype4_Text {
        font-size: 7px !important;
    }

    .PanLDetailringProperty {
        height: 92px !important;
        width: 78px !important;
        font-weight: 700;
    }
    .PnLDtype3_Text {
        font-size: 10px !important;
    }

    .PnLDtype1_Text {
        font-weight: 500;
        font-size: 15px !important;
    }
}

@media (-webkit-device-pixel-ratio: 1.25) {
    .USV-Graph {
        width: 100%;
        height: 123.68px !important;
    }

    .AVSGraph {
        width: 100%;
        height: 147px !important;
    }

    .BOprofitloss_Card {
        background-color: white;
        height: 186px;
        border-radius: 1.25rem;
        font-size: 40px;
    }

    .BOringProperty {
        height: 141px !important;
        width: 141px !important;
        padding: 12px 10px;
        font-weight: 700;
    }
}
