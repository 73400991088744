.service_Section {
    padding: 10px;
    .service-header {
        background-color: #2a4d90; /* Dark blue background */
        color: white; /* White text */
        text-align: center; /* Center the text */
        font-size: 21px; /* Adjust the font size */
        font-family: Arial, sans-serif; /* Choose a clean font */
        padding: 10px 0; /* Add vertical padding */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Add box shadow */
        font-weight: 600;
    }
}
